<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-9">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-header bg-transparent">
            <div class="text-center text-muted">
              <h4>{{getTrans('messages.sign_in')}}</h4>
              <small>{{getTrans('messages.reg_enter_details')}}</small>
            </div>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <form role="form">
              <base-input
                :formClasses="'input-group-merge input-group-alternative'"
                :placeholder="getTrans('messages.email')"
                prepend-icon="fas fa-envelope-square"
                addon-left-icon="ni ni-email-83"
                v-model="model.email"
                @keypress.enter="passPress"
                :error="getError('email')"
              ></base-input>
              <base-input
                :formClasses="'input-group-merge input-group-alternative'"
                :placeholder="getTrans('messages.password')"
                type="password"
                prepend-icon="fas fa-key"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="model.password"
                @keypress.enter="passPress"
                :error="getError('password')"
              ></base-input>
              <div class="d-flex justify-content-between">
                <base-checkbox
                  class="custom-control-alternative text-left"
                  v-model="model.rememberMe"
                >
                  <span class="text-muted">{{getTrans('messages.remember')}}</span>
                </base-checkbox>
                <div class="col-6 text-right">
                  <router-link to="/resetpassword" class="text-light">
                    <a href="#/resetpassword">{{getTrans('messages.reset_passw')}}</a>
                  </router-link>
                </div>
              </div>
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4 col-12"
                  @click="login"
                  :loading="loading"
                >{{getTrans('messages.sign_in')}}</base-button>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <router-link to="/register" class="text-light">
                    <a href="#/register">{{getTrans('messages.need_account')}}</a>
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Errors from "@/mixins/Errors";

export default {
  name: "login",
  mixins: [Errors],
  password: "password",
  data() {
    return {
      model: {
        email: "",
        password: "",
        rememberMe: false
      },
    };
  },
  computed: {
    ...mapGetters('User', {
      loading: 'loading',
      user: 'getData',
      errors: 'getErrors'
    }),
  },
  methods: {
    login() {
      this.$store
        .dispatch("User/login", {
          email: this.model.email,
          password: this.model.password,
          remember: this.model.rememberMe
        });
    },
    passPress() {
      this.login();
    },
  },
  watch: {
    user() {
      if (this.user) {
        this.$router.push({ name: 'dashboard' });
      }
    }
  },
  unmounted() {
    this.$store.dispatch("Error/RESET");
  }
};
</script>
<style></style>
